import { Avatar, Button, Divider, Input, Space } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import {
  ArrowDownOutlined,
  AudioOutlined,
  BellOutlined,
  LoadingOutlined,
  PauseOutlined,
  PhoneOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { BsTelephoneX } from "react-icons/bs";
import ForwardPhoneButton from "./ForwardPhoneButton";
import { useTranslations } from "next-intl";
import { ILineCall } from "@/providers/app/PhoneFeatures/types";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";
import { useInterval } from "usehooks-ts";
import { usePhoneFeatures } from "../../PhoneFeaturesProvider";
import { UnmountClosed } from "react-collapse";
import NumberInfo from "@/providers/app/PhoneFeatures/components/LineCall/NumberInfo";
import { useMutation } from "@tanstack/react-query";
import InComingCall from "@/providers/app/PhoneFeatures/components/InComingCall";
import FadeIn from "@/components/shared/FadeIn";
import { GlobalBorderRadiusPx } from "@/utils/app/constants";

interface IProps {
  line: ILineCall;
  collapsed: boolean;
}

function LineCall({ line, collapsed }: IProps) {
  const t = useTranslations("call");
  const [status, setStatus] = useState("");
  const [timer, setTimer] = useState("");
  const { PHONE_IFRAME_SYNC_SPEED } = useEnvironment();
  const {
    phoneContextRef,
    setActiveLineNumber,
    activeLineNumber,
    Lines,
    phoneOptions: { autoAnswer, callWaiting },
  } = usePhoneFeatures();
  const LineNumber = line?.LineNumber;
  const SipSession = line?.SipSession;
  const calldirection = SipSession?.data?.calldirection;
  const isActive = line?.LineNumber === activeLineNumber;
  // const isActive = true;
  const isInbound = calldirection === "inbound";
  const isOutbound = calldirection === "outbound";
  const started = SipSession?.data?.started;
  const ismute = SipSession?.data?.ismute;
  const isOnHold = SipSession?.isOnHold;
  const numberCalling = useMemo(() => {
    if (isInbound) {
      return SipSession?.data?.src;
    }
    if (isOutbound) {
      return SipSession?.data?.dst;
    }
  }, [isInbound, isOutbound, SipSession]);

  const { mutateAsync: holdSession, isPending: isHolding } = useMutation({
    mutationFn: async () => {
      phoneContextRef?.current?.holdSession(LineNumber);
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });

  const { mutateAsync: unholdSession, isPending: isUnholding } = useMutation({
    mutationFn: async () => {
      phoneContextRef?.current?.unholdSession(LineNumber);
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });

  const { mutateAsync: MuteSession, isPending: isMuting } = useMutation({
    mutationFn: async () => {
      phoneContextRef?.current?.MuteSession(LineNumber);
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });

  const { mutateAsync: UnmuteSession, isPending: isUnmuting } = useMutation({
    mutationFn: async () => {
      phoneContextRef?.current?.UnmuteSession(LineNumber);
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });

  const { mutateAsync: endSession, isPending: isCancelling } = useMutation({
    mutationFn: async () => {
      phoneContextRef?.current?.cancelSession(LineNumber);
      phoneContextRef?.current?.endSession(LineNumber);
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });
  const { mutateAsync: RejectCall, isPending: isRejecting } = useMutation({
    mutationFn: async () => {
      phoneContextRef?.current?.cancelSession(LineNumber);
      phoneContextRef?.current?.RejectCall(LineNumber);
      phoneContextRef?.current?.endSession(LineNumber);
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });

  const { mutateAsync: AnswerAudioCall, isPending: isAnswering } = useMutation({
    mutationFn: async () => {
      phoneContextRef?.current?.AnswerAudioCall(LineNumber);
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });
  const { mutateAsync: SwitchLine, isPending: isSwitching } = useMutation({
    mutationFn: async () => {
      setActiveLineNumber(LineNumber);
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED + 400)
      );
    },
  });

  const mappedStatus = useMemo(() => {
    if (status === "Ringing..." || status === "Session Progress...") {
      return (
        <>
          <BellOutlined style={{ color: "#FAAD14" }} />
          <span style={{ color: "#FAAD14" }} className="ms-2">
            {t("ringing")}
          </span>
        </>
      );
    }
    if (status === "Trying..." || status === "Starting Audio Call...") {
      return (
        <>
          <LoadingOutlined style={{ color: "#1890FF" }} />
          <span style={{ color: "#1890FF" }} className="ms-2">
            {t("connecting")}
          </span>
        </>
      );
    }
    if (status === "Call Rejected") {
      return (
        <>
          <span style={{ color: "#F5222D", marginRight: 4 }}>
            Cuộc gọi bị từ chối
          </span>
        </>
      );
    }
    if (status === "Call Failed") {
      return (
        <>
          <span style={{ color: "#F5222D", marginRight: 4 }}>
            Không thể gọi
          </span>
        </>
      );
    }
    if (status === "Call ended, bye!" || status === "Call Cancelled") {
      return (
        <>
          <span style={{ color: "#F5222D", marginRight: 4 }}>
            Cuộc gọi kết thúc
          </span>
        </>
      );
    }
    if (status === "Call ended, bye!") {
      return (
        <>
          <span style={{ color: "#F5222D", marginRight: 4 }}>
            Cuộc gọi kết thúc
          </span>
        </>
      );
    }
    if (status === "Call in Progress!" || status === "Incoming Call") {
      return <></>;
    }
    return <span>{status}</span>;
  }, [status, t]);

  useInterval(() => {
    setStatus(
      phoneContextRef?.current?.$("#line-" + LineNumber + "-msg")?.text()
    );
    setTimer(
      phoneContextRef?.current?.$("#line-" + LineNumber + "-timer")?.text()
    );
  }, PHONE_IFRAME_SYNC_SPEED);

  if (!autoAnswer) {
    if (
      (isInbound && !started && Lines?.length > 1) ||
      (isInbound && !started && collapsed)
    ) {
      return (
        <FadeIn>
          <InComingCall
            collapsed
            LineNumber={LineNumber}
            phoneNumber={numberCalling!}
          />
        </FadeIn>
      );
    }

    if (isInbound && !started) {
      return (
        <FadeIn>
          <InComingCall LineNumber={LineNumber} phoneNumber={numberCalling!} />
        </FadeIn>
      );
    }
  }

  if (collapsed) {
    return (
      <div className="p-1">
        <UnmountClosed isOpened={!isActive}>
          <FadeIn>
            <div
              onClick={SwitchLine}
              className="d-flex flex-column align-items-center justify-content-center">
              <Divider style={{ marginBottom: "12px" }} />
              <Button icon={<ArrowDownOutlined />}>
                Chuyển sang cuộc gọi này
              </Button>
            </div>
          </FadeIn>
        </UnmountClosed>
        <div
          onClick={isActive ? undefined : SwitchLine}
          className={
            isActive && Lines?.length > 1
              ? "active-line-call"
              : "non-active-line-call"
          }>
          <div className="d-flex flex-row justify-content-between">
            <NumberInfo size="small" phoneNumber={numberCalling!} />
            <div className="px-3 pt-3">
              <UnmountClosed isOpened={!started}>{mappedStatus}</UnmountClosed>
              <UnmountClosed isOpened={started}>
                <span>{timer}</span>
              </UnmountClosed>
            </div>
          </div>
          <div className="row gx-2 px-3 pb-3 mt-2">
            {status === "finished" ? (
              <>
                <div className="col-6">
                  <Button
                    className="w-100"
                    type="primary"
                    icon={<SaveOutlined />}>
                    {t("save")}
                  </Button>
                </div>
                <div className="col-6">
                  <Button className="w-100" icon={<PhoneOutlined />}>
                    {t("recall")}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="col-6 d-flex gap-3">
                  <div style={{ opacity: 0.3 }}>
                    <ForwardPhoneButton />
                  </div>
                  {ismute && (
                    <Button
                      loading={isMuting}
                      shape="circle"
                      type="primary"
                      disabled={!isActive}
                      onClick={() => UnmuteSession()}
                      icon={<AudioOutlined />}
                    />
                  )}
                  {!ismute && (
                    <Button
                      loading={isMuting}
                      shape="circle"
                      disabled={!isActive}
                      onClick={() => MuteSession()}
                      icon={<AudioOutlined />}
                    />
                  )}
                  {isOnHold && (
                    <Button
                      shape="circle"
                      type="primary"
                      loading={isUnholding}
                      disabled={!isActive}
                      onClick={() => unholdSession()}
                      icon={<PauseOutlined />}
                    />
                  )}
                  {!isOnHold && (
                    <Button
                      shape="circle"
                      loading={isHolding}
                      disabled={!isActive}
                      onClick={() => holdSession()}
                      icon={<PauseOutlined />}
                    />
                  )}
                </div>
                <div className="col-6">
                  <Button
                    disabled={
                      status === "Trying..." ||
                      status === "Starting Audio Call..."
                    }
                    type="primary"
                    loading={isCancelling}
                    className="w-100"
                    danger
                    onClick={() => endSession()}
                    icon={<BsTelephoneX />}>
                    {t("hang_up")}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="position-relative">
      <div
        className={
          isActive && Lines?.length > 1
            ? "active-line-call"
            : "non-active-line-call"
        }>
        <UnmountClosed isOpened={!isActive}>
          <FadeIn>
            <div
              onClick={() => SwitchLine()}
              className="d-flex flex-column align-items-center justify-content-center">
              <Divider style={{ marginBottom: "12px" }} />
              <Button loading={isSwitching} icon={<ArrowDownOutlined />}>
                Chuyển sang cuộc gọi này
              </Button>
            </div>
          </FadeIn>
        </UnmountClosed>
        <NumberInfo phoneNumber={numberCalling!} />
        <Divider className="my-2" />
        <UnmountClosed isOpened={isActive}>
          <div className="text-center fs-6">
            <UnmountClosed isOpened={!started}>{mappedStatus}</UnmountClosed>
            <UnmountClosed isOpened={started}>
              <span>{timer}</span>
            </UnmountClosed>
          </div>
        </UnmountClosed>
        <Divider className="my-2" />

        {status === "finished" ? (
          <>
            <Space className="w-100 px-3" direction="vertical">
              <p className="m-0">{t("note")}:</p>
              <Input.TextArea
                style={{
                  backgroundColor: "#F5F5F5",
                  height: 120,
                  resize: "none",
                  border: "none",
                  marginBottom: 48,
                }}
                placeholder="Nhập ghi chú"
              />
            </Space>
            <Space className="w-100 px-3 pb-3" direction="vertical">
              <Button className="w-100" type="primary" icon={<SaveOutlined />}>
                {t("save")}
              </Button>
              <Button className="w-100" icon={<PhoneOutlined />}>
                {t("recall")}
              </Button>
            </Space>
          </>
        ) : (
          <>
            <UnmountClosed isOpened={isActive}>
              <Space className="w-100 px-4 justify-content-center" size={32}>
                <div
                  style={{ opacity: 0.5 }}
                  onClick={() => {
                    alert("Tinh nang dang xay dung");
                  }}>
                  <ForwardPhoneButton isExtend />
                </div>
                <>
                  {ismute && (
                    <Space direction="vertical" align="center">
                      <Button
                        onClick={() => UnmuteSession()}
                        size="large"
                        type="primary"
                        shape="circle"
                        loading={isUnmuting}
                        icon={<AudioOutlined />}
                      />
                      <span>{t("turn_on_mic")}</span>
                    </Space>
                  )}
                  {!ismute && (
                    <Space direction="vertical" align="center">
                      <Button
                        loading={isMuting}
                        onClick={() => MuteSession()}
                        size="large"
                        shape="circle"
                        icon={<AudioOutlined />}
                      />
                      <span>{t("turn_off_mic")}</span>
                    </Space>
                  )}
                </>
                <>
                  {!isOnHold && (
                    <Space direction="vertical" align="center">
                      <Button
                        size="large"
                        shape="circle"
                        loading={isHolding}
                        onClick={() => holdSession()}
                        icon={<PauseOutlined />}
                      />
                      <span>{t("hold")}</span>
                    </Space>
                  )}
                  {isOnHold && (
                    <Space direction="vertical" align="center">
                      <Button
                        size="large"
                        shape="circle"
                        type="primary"
                        icon={<PauseOutlined />}
                        loading={isUnholding}
                        onClick={() => unholdSession()}
                      />
                      <span>{t("hold")}</span>
                    </Space>
                  )}
                </>
              </Space>
            </UnmountClosed>
            {isOutbound && (
              <div className="pb-3 mx-3">
                <Button
                  disabled={
                    status === "Trying..." ||
                    status === "Starting Audio Call..."
                  }
                  type="primary"
                  loading={isCancelling}
                  onClick={() => endSession()}
                  className="w-100 mt-3"
                  danger
                  icon={<BsTelephoneX />}>
                  {t("hang_up")}
                </Button>
              </div>
            )}
            {isInbound && (
              <div className="mx-3 row gx-2 mt-3 justify-content-between">
                {!started && (
                  <>
                    <div className="col-6">
                      <Button
                        type="primary"
                        loading={isAnswering}
                        onClick={() => AnswerAudioCall()}
                        block
                        icon={<PhoneOutlined />}>
                        {t("answer")}
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        type="primary"
                        loading={isRejecting}
                        block
                        onClick={() => RejectCall()}
                        danger
                        icon={<BsTelephoneX />}>
                        {t("hang_up")}
                      </Button>
                    </div>
                  </>
                )}
                {started && (
                  <div className="col-12">
                    <Button
                      disabled={
                        status === "Trying..." ||
                        status === "Starting Audio Call..."
                      }
                      type="primary"
                      loading={isCancelling}
                      block
                      onClick={() => endSession()}
                      danger
                      icon={<BsTelephoneX />}>
                      {t("hang_up")}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <Divider />
      </div>
    </div>
  );
}

export default LineCall;
