import { Space, notification, Button } from "antd";
import useMyAgent from "@/services/agent/useMyAgent";
import { useEffect, useMemo, useState } from "react";
import { useInterval } from "usehooks-ts";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";
import { useTranslations } from "next-intl";

const notificationKey = "check-call-conditions";

function useCheckCallConditions(phoneContextRef) {
  const t = useTranslations("call");
  const { PHONE_IFRAME_SYNC_SPEED } = useEnvironment();
  const [registerStatus, setRegisterStatus] = useState("waiting");
  const agentQuery = useMyAgent();
  const [HasAudioDevices, SetHasAudioDevices] = useState(false);

  const MappingRegisterStatus = useMemo(() => {
    if (registerStatus === "waiting") {
      return {
        original_status: "waiting",
        mapped_status: t("register_status.waiting"),
        color: "yellow",
        ready: false,
      };
    }
    if (registerStatus === "Connected to Web Socket!") {
      return {
        original_status: "Connected to Web Socket!",
        mapped_status: t("register_status.connected_socket"),
        color: "yellow",
        ready: false,
      };
    }
    if (registerStatus === "Registered") {
      return {
        original_status: "Registered",
        mapped_status: t("register_status.registered"),
        color: "blue",
        ready: true,
      };
    }
    return {
      original_status: registerStatus,
      mapped_status: t("register_status.failed"),
      color: "red",
      ready: false,
    };
  }, [registerStatus]);

  const checklist = useMemo(() => {
    return {
      microphone: {
        ready: HasAudioDevices,
      },
      register: {
        ...MappingRegisterStatus,
        ready: MappingRegisterStatus.ready,
      },
    };
  }, [HasAudioDevices, MappingRegisterStatus]);

  const checkCallConditions = useMemo(() => {
    const { data: myAgent, isLoading: isLoadingMyAgent } = agentQuery;

    return (
      !!myAgent?.data &&
      !isLoadingMyAgent &&
      (checklist?.microphone?.ready || false)
    );
  }, [agentQuery, checklist?.microphone?.ready]);

  const checkConditions = () => {
    return true;
    if (!checkCallConditions) {
      notification.error({
        key: notificationKey,
        message: "Không đủ điều kiện để thực hiện cuộc gọi",
        description: "Vui lòng kiểm tra lại thông tin tài khoản và thiết lập",
        placement: "top",
        style: {
          width: "480px",
        },
        duration: 0,
        btn: (
          <Space>
            <Button
              type="primary"
              size="middle"
              onClick={() => notification.destroy(notificationKey)}>
              Đóng
            </Button>
          </Space>
        ),
      });
      throw Error("conditions_not_met");
    }
    return true;
  };

  useInterval(
    () => {
      setRegisterStatus(phoneContextRef?.current?.$("#regStatus").text());
      const audioInputDevices = phoneContextRef?.current?.AudioinputDevices;
      if (audioInputDevices?.length && audioInputDevices[0]?.deviceId) {
        SetHasAudioDevices(true);
      } else {
        SetHasAudioDevices(false);
      }
    },
    HasAudioDevices ? null : PHONE_IFRAME_SYNC_SPEED
  );

  return {
    enabled: true,
    checklist,
    checkConditions,
    loading: checkCallConditions,
    error: [],
  };
}

export default useCheckCallConditions;
